<template>
	<claim-list scene="approve"></claim-list>
</template>

<script>
	import claimList from './claim_list_pc.vue'
	
	export default {
		name: 'claim_approve',
		
		components: {
			claimList
		}
	};
</script>

<style lang="stylus" scoped>
</style>