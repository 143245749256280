<template>
	<page>
		<module enable-flex>
			<m-search @search="updateList">
				<c-form>
					<c-form-item type="input" label="理赔项目" name="project_name"></c-form-item>
					<c-form-item type="input" label="金融机构" name=""></c-form-item>
					<c-form-item type="select" label="保单类型" name="bx_type_id" options="INSURANCE_TYPE"></c-form-item>
				</c-form>
			</m-search>
			
			<c-table ref="table">
				<c-table-column
					label="状态"
					width="100"
				>
					<template v-slot="{data}">
						<span v-if="claimState[data.state]" :style="{'color': claimState[data.state].color}">{{claimState[data.state].name}}</span>
					</template>
				</c-table-column>
				
				<c-table-column
					label="理赔项目名称"
					name="project_name"
					width="200"
				></c-table-column>
				
				<c-table-column
					label="保单类型"
					name="type_name"
					width="100"
				></c-table-column>
				
				<c-table-column
					label="金融机构"
					name="bxgs_name"
					width="250"
				></c-table-column>
				
				<c-table-column
					label="保单金额"
					width="160"
				>
					<template v-slot="{data}">
						￥{{data.bd_money}}
					</template>
				</c-table-column>
				
				<c-table-column
					label="申请时间"
					name="create_date"
					width="160"
				></c-table-column>
				
				<c-table-column
					type="button"
					label="操作"
					width="100"
					limit="2"
				>
					<template v-slot="{data}">
						<c-table-button v-if="data.state == 3" @click="navigateTo('/claim_detail', {id: data.id})">确认完结</c-table-button>
						<c-table-button v-else @click="navigateTo('/claim_detail', {id: data.id})">详情</c-table-button>
					</template>
				</c-table-column>
			</c-table>
		</module>
	</page>
</template>

<script>
	import {mapGetters} from 'vuex'
	
	export default {
		props: {
			scene: String
		},
		
		computed: {
			...mapGetters(['claimState'])
		},
		
		mounted() {
			this.updateList();
		},
		
		methods: {
			updateList(data = {}) {
				if (this.scene == 'approve') {
					data.state = 3;
				}
				this.$refs.table.load({
					url: '/project/bxlp/list',
					data
				});
			}
		}
	};
</script>

<style lang="stylus" scoped>
</style>