import { render, staticRenderFns } from "./claim_approve_pc.vue?vue&type=template&id=6325e6ca&scoped=true"
import script from "./claim_approve_pc.vue?vue&type=script&lang=js"
export * from "./claim_approve_pc.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6325e6ca",
  null
  
)

export default component.exports